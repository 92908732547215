import Bean from './Bean';
import Flight from './Flight';

export default class Trip extends Bean {

    hydrate(data = []) {
        super.hydrate(data);
        this.map('flights', Flight);
    }

    getFirstFlight() {
        for(const flight of this.flights) {
            return flight;
        }
        return null;
    }

}
