import { isset } from '@master';
export default class Bean {

    constructor(data) {
        this.hydrate(data);
        if(!isset(this.__calculated)) {
            this.__calculated = {};
        }
        if(!isset(this.__watch)) {
            this.__watch = {};
        }
        if(!isset(this.__data)) {
            this.__data = {};
        }
    }

    hydrate(data) {
        for(const key in data) {
            this[key] = data[key];
        }
        return this;
    }

    calculate(property, watch, callback) {
        if(!isset(this.__calculated[property])) {
            this.__calculated[property] = callback();
            this.watch(property, watch);
        }
        return this.__calculated[property];
    }

    watch(calculatedProperty, dataProperty) {
        if(!isset(this.__watch[dataProperty])) {
            this.__watch[dataProperty] = [];
        }
        this.__watch[dataProperty].push(calculatedProperty);
    }

    onChange(property) {
        if(isset(this.__watch) && isset(this.__watch[property])) {
            for(const calculatedProperty of this.__watch[property]) {
                delete this.__calculated[calculatedProperty];
            }
        }
    }

    create(obj, data) {
        return new (obj)(data);
    }

    cast(item, obj){
        this[item] = this.create(obj, this[item]);
        return this;
    }

    map(property, obj) {
        if(isset(this[property]) && Array.isArray(this[property])) {
            this[property].map((item, index) => {
                this[property][index] = this.create(obj, item);
            });
        } else if(isset(this[property]) && this[property] != null && typeof this[property] == 'object') {
            for(let [index, item] of Object.entries(this[property])) {
                this[property][index] = this.create(obj, item);
            }
        }
        return this;
    }
}
