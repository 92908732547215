import Bean from './Bean';

export default class Payment extends Bean {

    hydrate(data = []) {
        super.hydrate(data);
    }

    getBillingInformation() {
        return {
            firstName: this.firstName,
            lastName: this.lastName,
            address: this.address,
            line2: this.line2,
            city: this.city,
            state: this.state,
            postal: this.postal,
            country: this.country
        };
    }

}