import Bean from './Bean';
import FlightDetail from './FlightDetail';
import { Vue, isset } from "@master";

export default class Fare extends Bean {

    set flightDetails(data) {
        if(!isset(this.__data)) {
            this.__data = {};
        }
        this.__data['flightDetails'] = data;
        this.onChange('flightDetails');
    }

    get flightDetails() {
        if(!isset(this.__data)) {
            this.__data = {};
        }
        if(isset(this.__data['flightDetails'])) {
            return this.__data['flightDetails'];
        }
        return null;
    }

    hydrate(data = []) {
        super.hydrate(data);
        this.map('flightDetails', FlightDetail);
        this.cabinClassWeights = {
            economy: 1,
            premium: 2,
            business: 3,
            first: 3,
            null: 3
        };
        Vue.observable(this);
    }

    getHeaviestCabinClass() {
        return this.calculate('heaviestCabinClass', 'flightDetails', () => {
            let weight = 0;
            for(const segmentNumber in this.flightDetails) {
                let cabinClass = this.flightDetails[segmentNumber].cabinClass;
                if(this.cabinClassWeights[cabinClass] > weight) {
                    weight = this.cabinClassWeights[cabinClass];
                }
            }
            return weight;
        });
    }

    getSegmentCabinClassWeight(segmentNumber) {
        if(isset(this.flightDetails[segmentNumber])) {
            let cabinClass = this.flightDetails[segmentNumber].cabinClass;
            return this.cabinClassWeights[cabinClass]
        }
        return 0;
    }

    getFlightDetail(segmentNumber) {
        if(isset(this.flightDetails[segmentNumber])) {
            return this.flightDetails[segmentNumber];
        }
        return null;
    }

    getCabinClass(segmentNumber) {
        let flightDetails = this.getFlightDetail(segmentNumber);
        if(isset(flightDetails) && isset(flightDetails.cabinClass)) {
            return flightDetails.cabinClass;
        } else if(isset(this.cabinClass)) {
            return this.cabinClass;
        }
        return null;
    }

    getFareBasisCode(segmentNumber) {
        let flightDetails = this.getFlightDetail(segmentNumber);
        if(isset(flightDetails) && isset(flightDetails.fareBasisCode)) {
            return flightDetails.fareBasisCode
        }
        return null;
    }

    getBookingClass(segmentNumber) {
        let flightDetails = this.getFlightDetail(segmentNumber);
        if(isset(flightDetails) && isset(flightDetails.bookingClass)) {
            return flightDetails.bookingClass;
        }
        return null;
    }

    getFareFamily(segmentNumber) {
        let flightDetails = this.getFlightDetail(segmentNumber);
        if(isset(flightDetails) && isset(flightDetails.fareFamily)) {
            return flightDetails.fareFamily;
        }
        return null;
    }

    getTicketDesignator(segmentNumber) {
        let flightDetails = this.getFlightDetail(segmentNumber);
        if(isset(flightDetails) && isset(flightDetails.ticketDesignator)) {
            return flightDetails.ticketDesignator;
        }
        return null;
    }

    getCorporateId(segmentNumber) {
        let flightDetails = this.getFlightDetail(segmentNumber);
        if(isset(flightDetails) && isset(flightDetails.corporateId)) {
            return flightDetails.corporateId;
        }
        return null;
    }

    getFlightServices(segmentNumber, type) {
        let flightDetails = this.getFlightDetail(segmentNumber);
        if(isset(flightDetails) && isset(flightDetails.flightServices)) {
            return flightDetails.flightServices[type];
        }
        return null;
    }

    hasFlightService(segmentNumber, type) {
        let flightDetails = this.getFlightDetail(segmentNumber);
        if(isset(flightDetails) && isset(flightDetails.flightServices) && isset(flightDetails.flightServices[type])) {
            return true;
        }
        return false;
    }

    getIncludedBaggage(segmentNumber) {
        let flightDetails = this.getFlightDetail(segmentNumber);
        if(isset(flightDetails)) {
            return flightDetails.includedBaggage;
        }
        return null;
    }

    totalPrice(type='cash', withCommission=true) {
        let price;
        if(type == 'cash') {
            price = this.cashPrice;
        } else if (type == 'card') {
            price = this.price;
        }
        return price + ((this.collectCommission && withCommission) ? this.businessCommission : 0);
    }

}
