import Bean from './Bean';
import { isset } from "@master";

export default class InvoiceItem extends Bean {

    hydrate(data=[]) {
        super.hydrate(data);
        if(isset(this.items)) {
            this.map('items', InvoiceItem);
        }
    }

    getPrice() {
        if(isset(this.items)) {
            return this.items.map(item => item.price).reduce((total, price) => total += price, 0);
        }
        return this.price;
    }

}
