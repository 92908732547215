import Bean from './Bean';

export default class FareCollection extends Bean {

    constructor(data) {
        super(data);
        this.fares = [];
    }

    addFare(fare) {
        this.fares.push(fare);
    }

    isSelected() {
        return this.fares.every(fare => fare.selected);
    }

    getFareFamilies() {
        let fareFamilies = [];
        for(let [segmentNumber, flightDetail] of Object.entries(this.fares[0].flightDetails)) {
            if(flightDetail.fareFamily != null && !fareFamilies.includes(flightDetail.fareFamily.description)) {
                fareFamilies.push(flightDetail.fareFamily.description);
            }
        }
        return fareFamilies;
    }

    getNumberTravelers() {
        return this.fares.reduce((total, fare) => {
            if(fare.travelerType != 'INF') {
                total += fare.numTravelers
            }
            return total;
        }, 0);
    }

    getAveragePrice() {
        let total = 0;
        for(let fare of this.fares) {
            if(fare.travelerType != 'INF'){
                total += (fare.totalPrice('card', false) * fare.numTravelers);
            }
        }
        return total / this.getNumberTravelers();
    }

    getAverageCashPrice() {
        let total = 0;
        for(let fare of this.fares) {
            if(fare.travelerType != 'INF'){
                total += (fare.totalPrice('cash', false) * fare.numTravelers);
            }
        }
        return total / this.getNumberTravelers();
    }

    getFares() {
        return this.fares;
    }

}