import Bean from './Bean';
import InvoiceItem from './InvoiceItem';
import { isset } from "@master";

export default class Invoice extends Bean {

    hydrate(data = []) {
        super.hydrate(data);
        this.map('items', InvoiceItem);
    }

    getItem(type, travelerType='ADT', responseType='item', items=null) {
        if(items == null) {
            items = this.items;
        }
        let response = null;
        for(let [index, item] of Object.entries(items)) {
            if(item.typeCode == type && item.travelerTypeCode == travelerType) {
                if(responseType == 'item') {
                    return item;
                } else if(responseType == 'index') {
                    return index;
                }
            } else if(isset(item.items)) {
                response = this.getItem(type, travelerType, responseType, item.items);
                if(response != null) {
                    return response;
                }
            }
        }
        return null;
    }

    addCommissionField(fares) {
        for(let fare of fares) {
            this.items[fare.travelerType].items.push(new InvoiceItem({
                travelerTypeCode: fare.travelerType,
                typeCode: 'COMM',
                description: 'Commission',
                hide: fare.collectCommission,
                quantity: fare.numTravelers,
                price: fare.collectCommission ? fare.businessCommission : 0
            }));
        }
    }

}
