import Bean from './Bean';
import Itinerary from './Itinerary';
import Invoice from './Invoice';
import Passenger from './Passenger';
import Fare from './Fare';
import Payment from './Payment';
import { Vue, isset, moment } from "@master";

export default class Booking extends Bean {

    hydrate(data = []) {
        super.hydrate(data);
        this.cast('itinerary', Itinerary)
            .map('invoices', Invoice)
            .map('passengers', Passenger)
            .map('fares', Fare)
            .map('payments', Payment);

        if(!isset(this.contact)) {
            this.contact = {name: '',phone: ''};
        }
    }

    set passengers(data) {
        if(!isset(this.__data)) {
            this.__data = {};
        }
        this.__data['passengers'] = data;
        this.onChange('passengers');
    }

    get passengers() {
        if(!isset(this.__data)) {
            this.__data = {};
        }
        if(isset(this.__data['passengers'])) {
            return this.__data['passengers'];
        }
        return null;
    }

    createPassengersFromFares() {
        this.passengers = [];
        for(let fare of this.itinerary.fares) {
            for(let i = 0; i < fare.numTravelers; i++) {
                const traveler = Passenger.createFromFare(fare);
                this.passengers.push(traveler);
            }
        }
        return this.passengers;
    }

    createEmptyPayment(state = null) {
        const payment = new Payment();
        if(state !== null) {
            payment.state = state;
        }
        this.hasBlankPayment = true;
        if(!isset(this.payments)) {
            this.payments = [];
        }
        this.payments.push(Vue.observable(payment));
    }

    sortPassengers() {
        this.passengers = this.passengers.sort((a, b) => {
            if(a.travelerType < b.travelerType) return -1;
            if(a.travelerType > b.travelerType) return 1;
            return 0;
        });
    }

    hasFareQualifications() {
        if(['CRU', 'MIS', 'TOU', 'STU'].includes(this.itinerary.fareTypeCode) && this.fareQualification != null) {
            return true;
        }
        return false;
    }

    missingFareQualifications() {
        return Object.values(this.fareQualification).filter(value => {
            if(value != '' && value != null) {
                return value;
            }
        }).length == 0;
    }

    missingTravelerContact() {
        return Object.values(this.contact).filter(value => {
            if(value != '' && value != null) {
                return value;
            }
        }).length == 0;
    }

    isFareType(fareType) {
        return fareType === this.itinerary.fareTypeCode;
    }

    setFareQualifications(fareQualificationValues) {
        let qualification = {};

        if(fareQualificationValues) {
            qualification = fareQualificationValues;
            if(qualification.sailDate) {
                qualification.sailDate = moment(fareQualificationValues.sailDate);
            }
            this.fareQualification = Vue.observable(qualification);
            return;
        }

        switch(this.itinerary.fareTypeCode) {
            case "CRU":
                qualification = {
                    name: "",
                    sailDate: null,
                    reservationId: ""
                };
                break;
            case "TOU":
                qualification = {
                    name: "",
                    reservationId: ""
                };
                break;
            case "MIS":
                qualification = {
                    name: "",
                    taxId: "",
                    website: "",
                    address: "",
                    address2: "",
                    city: "",
                    state: "",
                    postalCode: "",
                    country: ""
                };
                break;
            case "STU":
                qualification = {
                    name: "",
                    city: "",
                    state: "",
                    country: "",
                    idNumber: ""
                }
                break;

        }
        this.fareQualification = Vue.observable(qualification);
    }

    getPassengerByType(type = 'ADT') {
        const passengers = [];
        for(const passenger of this.passengers) {
            if(passenger.travelerType === type) {
                passengers.push(passenger);
            }
        }
        return passengers
    }

    getPrimaryTraveler() {
        return this.calculate('primaryPassenger', 'passengers', () => {
            for(const passenger of this.passengers) {
                return passenger;
            }
            return null;
        });
    }

    totalPaid() {
        return this.payments.reduce((total, payment) => total += payment.amount, 0);
    }

    setCollectCommission(collectCommission) {
        for(const fare of this.itinerary.fares) {
            fare.collectCommission = collectCommission;
        }
    }

    collectCommission() {
        return this.itinerary.fares.map(fare => fare.collectCommission).some(collect => collect == true);
    }

    showDocuments() {
        if(this.business.allowReseller) {
            return true;
        }
        if(this.datePaid != null) {
            return true;
        }
        return false;
    }

    showEticketReceipt(isAdmin=false) {
        if(isAdmin) {
            return this.datePaid != null;
        } else {
            return ['ticketed', 'flown'].includes(this.status);
        }
    }

    showTravelerReceipt(isAdmin=false) {
        if(isAdmin) {
            return this.business.allowReseller && this.datePaid != null;
        } else {
            return this.business.allowReseller && ['purchased', 'ticketed', 'flown'].includes(this.status);
        }
    }

    showTravelerConfirmation(isAdmin=false) {
        if(isAdmin) {
            return this.business.allowReseller && this.datePaid == null;
        } else {
            return this.business.allowReseller && ['purchased'].includes(this.status);
        }
    }

    isTicketed() {
        return this.status === 'ticketed';
    }

    hasPayments() {
        if(isset(this.payments)) {
            return this.payments.length > 0;
        }
        return false;
    }

    getInvoice(type) {
        for(let invoice of this.invoices) {
            if(invoice.type == type) {
                return invoice;
            }
        }
        return null;
    }

}
