import Booking from './Booking';
import Itinerary from './Itinerary';
import Invoice from './Invoice';
import InvoiceItem from './InvoiceItem';
import Trip from './Trip';
import Flight from './Flight';
import Passenger from './Passenger';
import Fare from './Fare';
import Payment from './Payment';
import FareCollection from './FareCollection';

export default class BeanFactory {

    constructor(data) {
        this.hydrate(data);
    }

    static get alias() {
        return {
            'Booking': Booking,
            'Itinerary': Itinerary,
            'Invoice': Invoice,
            'InvoiceItem': InvoiceItem,
            'Trip': Trip,
            'Flight': Flight,
            'Passenger': Passenger,
            'Fare': Fare,
            'Payment': Payment,
            'FareCollection': FareCollection,
        }
    }

    static create(aliasName, data=[]) {
        return new BeanFactory.alias[aliasName](data);
    }

}