import Bean from './Bean';
import { Vue } from "@master";

class Passenger extends Bean {

    static createFromFare(fare) {
        const passenger = new Passenger();
        passenger.id = null;
        passenger.key = passenger.getKey()
        passenger.travelerType = fare.travelerType;
        passenger.travelerTypeLabel = fare.travelerTypeLabel;
        passenger.panelOpen = true;
        passenger.saveTraveler = true;
        passenger.additionalInfo = [];
        passenger.gender = '';
        passenger.firstName = '';
        passenger.middleName = '';
        passenger.lastName = '';
        passenger.clear('birthday');
        passenger.clear('frequentFlyer');
        passenger.clear('passport');
        passenger.clear('knownTraveler');
        passenger.clear('redress');
        passenger.clear('specialAssistance');
        return Vue.observable(passenger);
    }

    reset() {
        this.id = null;
        this.panelOpen = true;
        this.additionalInfo = [];
        this.saveTraveler = true;
        this.gender = '';
        this.firstName = '';
        this.middleName = '';
        this.lastName = '';
        this.clear('birthday');
        this.clear('frequentFlyer');
        this.clear('passport');
        this.clear('knownTraveler');
        this.clear('redress');
        this.clear('specialAssistance');
    }

    clear(property) {
        let func = `clear${property.charAt(0).toUpperCase()+property.slice(1)}`;
        if(typeof this[func] === 'function') {
            this[func]();
        }
    }

    clearBirthday() {
        this.birthDate = {
            year: '',
            month: '',
            date: ''
        };
    }

    clearFrequentFlyer() {
        this.hasFrequentFlyer = false;
        this.frequentFlyer  = {
            program: '',
            number : ''
        };
    }

    clearPassport() {
        this.hasPassport = false;
        this.passport  = {
            number: '',
            country: 'USA',
            expiration: {
                year: '',
                month: '',
                date: ''
            }
        };
    }

    clearKnownTraveler() {
        this.hasKnownTravelerNumber = false;
        this.knownTravelerNumber = '';
    }

    clearRedress() {
        this.hasRedress = false;
        this.redress  = {
            number: '',
            country: 'USA'
        };
    }

    clearSpecialAssistance() {
        this.hasSpecialAssistance = false;
        this.specialAssistance = '';
    }

    getKey() {
        return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    }

}

export default Passenger;
